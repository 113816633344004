<!--
 * @Author: 庄志莹
 * @Date: 2020-12-11 16:48:21
 * @LastEditTime: 2021-06-09 11:31:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\404\404.vue
-->
<template>
  <div>
    <a-result status="404" title="404" sub-title="对不起，您访问的页面不存在">
      <template #extra>
        <a-button type="primary" @click="login"> 返回登录 </a-button>
      </template>
    </a-result>
    <div class="icp d-f a-c j-c">
      ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号 江苏小牛电子科技有限公司
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.icp {
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 12px;
}
</style>
